<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <rc-overlay
        :show="loading"
        no-fade
        variant="white"
        class="h-100"
      >

        <div
          v-if="!service.id && !addingService && !loading"
          class="start-chat-area"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon
              icon="UserIcon"
              size="56"
            />
          </div>
          <div>
            <span
              class="sidebar-toggle start-chat-text"
              :class="{'app-tour-services-show-sidebar-button': !(mqShallShowLeftSidebar || largeScreen)}"
              @click="startConversation"
            >{{ $t('services.select-service') }}</span>
            <span> {{ $t('shared.or') }} </span>
            <span
              class="start-chat-text"
              @click="initAddService"
            >{{ $t('services.add-new-service') }}</span>
          </div>
        </div>

        <!-- Service Content -->
        <div
          v-else-if="service.id || addingService"
          class="active-chat"
        >
          <!-- Service Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">

              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">

                <!-- Toggle Icon -->
                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer app-tour-services-show-sidebar-button"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>
                <h6 class="mb-0">
                  {{ service.name }}<br>
                  <span class="font-small-3 text-muted">
                    {{ service.shortDescription }}
                  </span>
                </h6>
              </div>

              <!-- Service Actions -->
              <div class="d-flex align-items-center">

                <b-button
                  variant="flat-primary"
                  class="mx-1 d-sm-block d-none app-tour-services-edit-button"
                  @click="toggleEditMode"
                >
                  <feather-icon
                    id="edit-view-service-toggle-icon"
                    :icon="editMode === true ? 'EyeIcon' : 'Edit2Icon'"
                    size="17"
                  />
                  <b-tooltip
                    target="edit-view-service-toggle-icon"
                    noninteractive
                  >
                    {{ editMode === true ? $t('shared.view') : $t('shared.edit') }}
                  </b-tooltip>
                </b-button>

                <b-button
                  v-if="editMode"
                  variant="success"
                  class="mr-1 mr-sm-0 app-tour-services-save-button"
                  @click="$refs.serviceForm.saveService()"
                >
                  <feather-icon icon="SaveIcon" />
                  <span class="ml-50 d-none d-sm-inline-block">
                    {{ $t('shared.btn.save') }}
                  </span>
                </b-button>

                <div class="dropdown d-sm-none d-block app-tour-services-service-menu-icon">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body "
                      />
                    </template>
                    <b-dropdown-item
                      class="d-sm-none d-block app-tour-services-edit-button"
                      @click="toggleEditMode"
                    >
                      {{ editMode === true ? $t('shared.view') : $t('shared.edit') }}
                    </b-dropdown-item>
                    <!--                  <b-dropdown-item>-->
                    <!--                    Archive-->
                    <!--                  </b-dropdown-item>-->
                  </b-dropdown>
                </div>
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <service-form
              v-if="editMode"
              ref="serviceForm"
              :service="service"
              @service-added="serviceAddedHandler"
              @service-saved="serviceSavedHandler"
              @leaving-adding-form="addingService = false"
            />
            <service-view
              v-else
              :service="service"
            />
          </vue-perfect-scrollbar>
        </div>

      </rc-overlay>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <service-left-sidebar
        :services="services"
        :active-service-id="service ? service.id : null"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @open-service-view="openServiceView"
        @add-new-service="initAddService"
      />
    </portal>
  </div>
</template>

<script>
import { computed, nextTick, ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import ServiceLeftSidebar from './ServiceLeftSidebar.vue'
import router from '@/router'
import ServiceForm from '@/views/organization/services/ServiceForm'
import ServiceView from '@/views/organization/services/ServiceView'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

export default {
  components: {
    ServiceView,
    ServiceForm,

    RcOverlay,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ServiceLeftSidebar,
  },
  computed: {
    services() {
      return this.$store.getters['service/getServices']
    },
    service() {
      return this.$store.getters['service/getService']
    },
  },
  beforeMount() {
    this.$store.dispatch('service/fetchServices')
    if (this.$route.params.serviceId) {
      this.loading = true
      this.$store.dispatch(
        'service/fetchService',
        this.$route.params.serviceId,
      )
        .then(() => { this.loading = false })
    }
  },
  setup() {
    const atLeastOneServiceLoaded = ref(false)
    const loading = ref(false)
    const addingService = ref(false)
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const resetServiceView = () => {
      loading.value = false
      // const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      // scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const editMode = ref(router.currentRoute.name === 'services-edit')
    const toggleEditMode = () => {
      editMode.value = !editMode.value
      window.history.pushState(null, null, `/organization/services/${store.getters['service/getService'].id}/${editMode.value === true ? 'edit' : 'view'}`)
    }

    const openServiceView = serviceId => {
      if (router.currentRoute.name === 'services-view' && serviceId === store.getters['service/getService'].id) {
        return
      }

      editMode.value = false
      window.history.pushState(null, null, `/organization/services/${serviceId}/view`)
      loading.value = true
      store.dispatch('service/fetchService', serviceId)
        .then(() => {
          nextTick(() => { resetServiceView() })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const initAddService = () => {
      addingService.value = true
      editMode.value = true
      store.dispatch('service/resetService')
      mqShallShowLeftSidebar.value = false
    }

    const serviceAddedHandler = service => {
      openServiceView(service.id)
      addingService.value = false
      store.dispatch('service/fetchServices')
    }

    const serviceSavedHandler = service => {
      if (!service.archived) {
        return
      }
      store.dispatch('service/resetService')
      store.dispatch('service/fetchServices')
      addingService.value = false
      editMode.value = false
      window.history.pushState(null, null, '/organization/services')
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const largeScreen = computed(() => store.state.app.windowWidth >= $themeBreakpoints.lg)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    if (router.currentRoute.name === 'services' && store.state.app.windowWidth < $themeBreakpoints.lg) {
      mqShallShowLeftSidebar.value = true
    }
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      atLeastOneServiceLoaded,
      loading,
      addingService,

      openServiceView,
      toggleEditMode,
      serviceAddedHandler,
      serviceSavedHandler,
      initAddService,

      // UI
      editMode,
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      largeScreen,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "../../../assets/scss/base/pages/app-customers";
@import "../../../assets/scss/base/pages/app-customers-list";
</style>
