<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <b-card class="shadow-none app-tour-service-view-statistics">

          <rc-overlay :show="loading">
            <b-row class="match-height">
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.plannedEvents }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('services.statistics.planned-events') }}
                </h6>
              </b-col>

              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.pastEvents }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('services.statistics.past-events') }}
                </h6>
              </b-col>

              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ getIntegers(statistics.incomePaid) }}<span
                    class="font-small-4 text-muted"
                  >.{{ getDecimals(statistics.incomePaid) }} zł</span>
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('services.statistics.income-paid') }}
                </h6>
              </b-col>

              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ getIntegers(statistics.incomeToPay) }}<span
                    class="font-small-4 text-muted"
                  >.{{ getDecimals(statistics.incomeToPay) }} zł</span>
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('services.statistics.income-to-pay') }}
                </h6>
              </b-col>

            </b-row>

          </rc-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import { getDecimals, getIntegers } from '@/helpers/helpers'

export default {
  components: {
    RcOverlay,
    BRow,
    BCol,
    BCard,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    statistics() {
      return this.$store.getters['service/statistics']
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true

      this.$store.dispatch('service/fetchStatistics', {
        serviceId: this.service.id,
      })
        .finally(() => {
          this.loading = false
        })
    },
    getIntegers,
    getDecimals,
  },
}
</script>

<style scoped>
.card {margin-bottom: 1rem;}
</style>
