<template>
  <div>

    <validation-observer ref="serviceFormValidation">
      <form
        id="editServiceForm"
        @submit.prevent
      >
        <b-row class="match-height">

          <b-col
            xl="6"
            md="12"
          >
            <b-card
              class="shadow-none app-tour-services-edit-base-information"
              :title="$t('services.form.base-information.title')"
            >

              <b-row class="mb-50">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.name')"
                    rules="required|min:2"
                  >
                    <label for="name">
                      {{ $t('services.form.base-information.name') }}
                    </label>
                    <b-form-input
                      id="name"
                      v-model="service.name"
                      type="text"
                      minlength="2"
                      maxlength="50"
                      :placeholder="$t('services.form.base-information.name')"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.short-description')"
                    rules="min:2"
                  >
                    <label for="shortDescription">
                      {{ $t('services.form.base-information.short-description') }}
                    </label>
                    <b-form-input
                      id="shortDescription"
                      v-model="service.shortDescription"
                      type="text"
                      minlength="2"
                      maxlength="250"
                      :placeholder="$t('services.form.base-information.short-description')"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>

                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.duration')"
                    rules="required|min_value:5"
                  >
                    <label for="duration">
                      {{ $t('services.form.base-information.duration') }}
                    </label>
                    <duration-selector
                      id="duration"
                      v-model="service.duration"
                      :max-hour="23"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>

                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col
                  cols="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.price-gross')"
                    rules="required"
                  >
                    <label for="priceGross">
                      {{ $t('services.form.base-information.price-gross') }}
                    </label>
                    <b-input-group
                      append="zł"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="priceGross"
                        v-model="service.priceGross"
                        type="number"
                        step="0.01"
                        value="0.00"
                        class="text-right"
                        placeholder="50.00"
                        autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.tax-percent')"
                    rules="required"
                  >
                    <label for="taxPercent">
                      {{ $t('services.form.base-information.tax-percent') }}
                    </label>
                    <b-input-group
                      append="%"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="taxPercent"
                        v-model="service.taxPercent"
                        class="text-right"
                        type="number"
                        placeholder="23"
                        autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('services.form.base-information.price-type')"
                    rules="required"
                  >
                    <label for="priceGross">
                      {{ $t('shared.price-types.title') }}
                    </label>
                    <v-select
                      id="selfReservationType"
                      v-model="service.priceType"
                      :options="priceTypes"
                      :clearable="false"
                      :searchable="false"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <b-col
            xl="6"
            md="12"
          >
            <b-card
              class="shadow-none app-tour-services-edit-default-settings"
              :title="$t('services.form.default-settings.title')"
            >

              <b-row class="mb-50">
                <b-col>
                  <label for="serviceLocation">
                    {{ $t('shared.service-location.title') }}
                  </label>
                  <v-select
                    id="serviceLocation"
                    v-model="service.serviceLocation"
                    :options="serviceLocations"
                    multiple
                    :clearable="false"
                    :searchable="false"
                    :reduce="(option) => option.id"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <label for="additionalTimeBefore">
                    {{ $t('services.form.default-settings.additional-time-before') }}
                  </label>
                  <duration-selector
                    id="additionalTimeBefore"
                    v-model="service.additionalTimeBefore"
                    :max-hour="12"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <label for="additionalTimeAfter">
                    {{ $t('services.form.default-settings.additional-time-after') }}
                  </label>
                  <duration-selector
                    id="additionalTimeAfter"
                    v-model="service.additionalTimeAfter"
                    :max-hour="12"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <label for="additionalTimeAfter">
                    {{ $t('services.form.default-settings.service-color') }}
                  </label>
                  <div>
                    <b-avatar
                      v-for="color in colors"
                      :key="color"
                      button
                      :style="`background-color: ${color} !important;`"
                      class="mr-25 mb-25"
                      @click="service.color = color"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        :size="service.color === color ? '20' : '0'"
                      />
                    </b-avatar>
                  </div>
                </b-col>
              </b-row>

            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card
              class="shadow-none app-tour-services-edit-public-info"
              :title="$t('services.form.public-info.title')"
              :sub-title="$t('services.form.public-info.subtitle')"
            >
              <label
                for="isServicePublic"
                class="w-100 font-medium-1 cursor-pointer"
              >
                <b-form-checkbox
                  id="isServicePublic"
                  v-model="service.public"
                  class="float-left"
                  name="check-button"
                  switch
                />
                {{ $t('services.form.public-info.is-public') }}
              </label>

              <h5 class="mt-2">
                {{ $t('services.form.public-reservation.title') }}
              </h5>

              <b-row>
                <b-col
                  xl="6"
                  md="12"
                >
                  <b-row class="mb-50">
                    <b-col>
                      <label for="selfReservationType">
                        {{ $t('shared.self-reservation-types.title') }}
                      </label>
                      <v-select
                        id="selfReservationType"
                        v-model="service.selfReservationType"
                        :options="selfReservationTypes"
                        :clearable="false"
                        :searchable="false"
                        :reduce="(option) => option.id"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="mb-50">
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('services.form.public-reservation.participant-limit')"
                        rules="required|min_value:1"
                      >
                        <label for="participantLimit">
                          {{ $t('services.form.public-reservation.participant-limit') }}
                        </label>
                        <b-form-input
                          id="participantLimit"
                          v-model="service.participantLimit"
                          type="number"
                          :placeholder="$t('services.form.public-reservation.participant-limit')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  xl="6"
                  md="12"
                >
                  <b-row class="mb-50">
                    <b-col>
                      <label for="reservationInterval">
                        {{ $t('services.form.public-reservation.reservation-interval') }}
                      </label>
                      <duration-selector
                        id="reservationInterval"
                        v-model="service.reservationInterval"
                        :max-hour="6"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="mb-50">
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('services.form.public-reservation.service-time-from')"
                        rules="time"
                      >
                        <label for="serviceTimeFrom">
                          {{ $t('services.form.public-reservation.service-time-from') }}
                        </label>
                        <cleave
                          id="serviceTimeFrom"
                          v-model="service.serviceTimeFrom"
                          class="form-control"
                          :raw="false"
                          :options="inputMasks.time"
                          placeholder="hh:mm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('services.form.public-reservation.service-time-to')"
                        rules="time"
                      >
                        <label for="serviceTimeTo">
                          {{ $t('services.form.public-reservation.service-time-to') }}
                        </label>
                        <cleave
                          id="serviceTimeTo"
                          v-model="service.serviceTimeTo"
                          class="form-control"
                          :raw="false"
                          :options="inputMasks.time"
                          placeholder="hh:mm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-50"
          >
            <b-card
              :title="$t('services.form.danger-zone.title')"
              class="shadow-none border-danger  text-danger"
            >
              <label
                for="isServiceArchived"
                class="w-100 font-medium-1 cursor-pointer text-danger"
              >
                <b-form-checkbox
                  id="isServiceArchived"
                  v-model="service.archived"
                  class="custom-control-danger float-left"
                  name="check-button"
                  switch
                />
                {{ $t('services.form.danger-zone.archived') }}
              </label>
            </b-card>
          </b-col>
        </b-row>

      </form>
    </validation-observer>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BCard, BCol, BFormInput, BInputGroup, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import {
  alphaNum, digits, length, required, minValue, maxValue, time,
} from '@/libs/validations/validations'
import { parseRequestError } from '@/helpers/helpers'
import DurationSelector from '@/layouts/components/rc/DurationSelector'

export default {
  components: {
    DurationSelector,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BInputGroup,

    vSelect,
    Cleave,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      length,
      minValue,
      maxValue,
      time,
      colors: [
        '#B91C1C',
        '#C2410C',
        '#B45309',
        '#A16207',
        '#4D7C0F',
        '#15803D',
        '#047857',
        '#0F766E',
        '#3a859b',
        '#0E7490',
        '#0369A1',
        '#4338CA',
        '#6D28D9',
        '#A21CAF',
        '#BE185D',
        '#BE123C',
        '#44403C',
        '#3F3F46',
        '#334155',
      ],
      priceTypes: [
        { id: 'PerMeeting', label: this.$t('shared.price-types.PerMeeting') },
        { id: 'PerMonth', label: this.$t('shared.price-types.PerMonth') },
        { id: 'PerCycle', label: this.$t('shared.price-types.PerCycle') },
      ],
      selfReservationTypes: [
        { id: 'unavailable', label: this.$t('shared.self-reservation-types.unavailable') },
        { id: 'prepared_spot', label: this.$t('shared.self-reservation-types.prepared_spot') },
        { id: 'freely', label: this.$t('shared.self-reservation-types.freely') },
      ],
      serviceLocations: [
        { id: 'online', label: this.$t('shared.service-location.online') },
        { id: 'at_the_teacher', label: this.$t('shared.service-location.at_the_teacher') },
        { id: 'at_the_customer', label: this.$t('shared.service-location.at_the_customer') },
      ],
      inputMasks: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
    }
  },
  beforeDestroy() {
    this.$emit('leaving-adding-form')
  },
  methods: {
    saveService() {
      this.$refs.serviceFormValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          if (this.service.id) {
            this.$store.dispatch(
              'service/editService',
              { serviceId: this.service.id, data: this.service },
            )
              .then(() => {
                this.$emit('service-saved', this.service)
                window.toast.notify.success(this.$t('services.form.notifications.updated'))
              })
              .catch(err => parseRequestError(err))
          } else {
            this.$store.dispatch(
              'service/addService',
              { data: this.service },
            )
              .then(response => {
                this.$emit('service-added', response.data)
                window.toast.notify.success(this.$t('services.form.notifications.added'))
              })
              .catch(err => parseRequestError(err))
          }

          return true
        })
    },
  },
}
</script>
