<template>
  <div>
    <service-view-statistics :service="service" />

    <div class="app-tour-service-view-details">
      <b-row class="match-height">
        <b-col>
          <b-card
            class="shadow-none"
            :title="$t('services.form.base-information.title')"
          >

            <b-row
              v-if="service.shortDescription"
              class="mb-1"
            >
              <b-col class="mb-50">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.base-information.short-description') }}
                </h6>
                {{ service.shortDescription }}
              </b-col>
            </b-row>

            <b-row
              class="mb-1"
            >

              <b-col cols="6">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.base-information.price-gross') }}
                </h6>
                {{ service.priceGross }} zł {{ $t(`shared.price-types.${service.priceType}`).toLowerCase() }}
              </b-col>

              <b-col cols="6">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.base-information.tax-percent') }}
                </h6>
                {{ service.taxPercent }}%
              </b-col>
            </b-row>

            <b-row
              class="mb-1"
            >
              <b-col cols="6">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.base-information.duration') }}
                </h6>
                {{ toHours(service.duration) }}h {{ toMinutes(service.duration) }}min.
              </b-col>

              <b-col cols="6">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.is-public') }}
                </h6>
                {{ service.public ? $t('shared.yes') : $t('shared.no') }}
              </b-col>

            </b-row>

          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col>
          <b-card
            class="shadow-none"
            :title="$t('services.form.default-settings.title')"
          >

            <b-row
              class="mb-1"
            >

              <b-col cols="6">
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('shared.service-location.title') }}
                </h6>
                <span
                  v-for="(location, key) in service.serviceLocation"
                  :key="key"
                >
                  {{ $t(`shared.service-location.${location}`) }},
                </span>
              </b-col>
            </b-row>

            <b-row
              class="mb-1"
            >

              <b-col
                v-if="service.additionalTimeBefore"
              >
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.default-settings.additional-time-before') }}
                </h6>
                {{ toHours(service.additionalTimeBefore) }}h {{ toMinutes(service.additionalTimeBefore) }}min.
              </b-col>

              <b-col
                v-if="service.additionalTimeAfter"
              >
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.default-settings.additional-time-after') }}
                </h6>
                {{ toHours(service.additionalTimeAfter) }}h {{ toMinutes(service.additionalTimeAfter) }}min.
              </b-col>

            </b-row>

          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col>
          <b-card
            class="shadow-none"
            :title="$t('services.form.public-reservation.title')"
          >

            <b-row
              class="mb-1"
            >
              <b-col>
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('shared.self-reservation-types.title') }}
                </h6>
                {{ $t(`shared.self-reservation-types.${service.selfReservationType}`) }}
              </b-col>

              <b-col>
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.public-reservation.participant-limit') }}
                </h6>
                {{ service.participantLimit }}
              </b-col>

              <b-col
                v-if="service.serviceTimeFrom && service.serviceTimeTo"
              >
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.public-reservation.service-time-range') }}
                </h6>
                {{ service.serviceTimeFrom }} - {{ service.serviceTimeTo }}
              </b-col>

              <b-col
                v-else-if="service.serviceTimeFrom"
              >
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.public-reservation.service-time-from') }}
                </h6>
                {{ service.serviceTimeFrom }}
              </b-col>

              <b-col
                v-else-if="service.serviceTimeTo"
              >
                <h6 class="text-muted text-uppercase font-small-3">
                  {{ $t('services.form.public-reservation.service-time-to') }}
                </h6>
                {{ service.serviceTimeTo }}
              </b-col>

            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ServiceViewStatistics from '@/views/organization/services/ServiceViewStatistics'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ServiceViewStatistics,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toHours(val) {
      return Math.floor(val / 60).toString()
    },
    toMinutes(val) {
      return (val - Math.floor(val / 60) * 60).toString()
    },
  },
  setup() {
    const updating = ref(false)
    const setUpdating = newUpdating => { updating.value = newUpdating }

    return {
      updating,
      setUpdating,
    }
  },
}
</script>

<style scoped>
.card {margin-bottom: 1rem;}
</style>
