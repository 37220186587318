<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      :style="`background-color: ${service.color} !important;`"
      class="my-50"
      size="18"
    >
      <feather-icon
        size="0"
        icon="MenuIcon"
      />
    </b-avatar>
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ service.name }}
      </h5>
      <p class="card-text text-truncate font-small-2">
        {{ service.shortDescription }}
      </p>
    </div>
    <!--    <div-->
    <!--      class="chat-meta text-nowrap"-->
    <!--    >-->
    <!--      <b-badge-->
    <!--        pill-->
    <!--        variant="primary"-->
    <!--        class="float-right"-->
    <!--      >-->
    <!--        {{ 1 }}-->
    <!--      </b-badge>-->
    <!--    </div>-->
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    service: {
      type: Object,
      required: true,
    },
  },
}
</script>
